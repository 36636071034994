<template>
  <vx-card title="Sliders">
    <p>Aquí se pueden modificar los Sliders que aparecen en el Home.</p>

    <div v-if="initProgress" style="margin-top: 1rem; width: 100%">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>

    <div class="mt-5">
      <!-- Sliders -->
      <div v-for="(slider, index) in content.sliders" :key="index">
        <div>
          <!-- Image Container -->
          <div class="img-container flex items-center justify-center">
            <img :src="slider.image" alt="img" class="responsive">

          </div>
          <!-- Image upload Buttons -->
          <div class="modify-img flex justify-between mt-5">
            <input type="file" class="hidden" :id="`slider${index}`" @change="updateSlider($event,slider)"
                   accept="image/*">
            <span style="cursor: pointer" class="ml-2" type="flat"
                  @click="changeSlider(`slider${index}`)">Cambiar
                                </span>
            <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                  @click="deleteImage(index)">Eliminar</span>
          </div>
        </div>
        <div class="mt-5">
          <vs-input @blur="slider.path= trimString(slider.path)" label="Path (IZQ.)" v-model="slider.path"
                    class="mt-5 w-full" name="path"
                    v-validate="'required'"/>
        </div>
        <div class="mt-5">
          <vs-input @blur="slider.path1= trimString(slider.path1)" label="Path (DCHA.)" v-model="slider.path1"
                    class="mt-5 w-full" name="path1"
                    v-validate="'required'"/>
        </div>
        <hr class="mt-5 mb-5">
      </div>

      <!--Add image-->
      <vs-button style="width: 100%" @click="addSlider">Agregar slider (1366 x 350)</vs-button>
      <!--End-->

      <div class="flex mt-6 flex-wrap items-center" slot="footer">
        <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
        <div v-if="progress" style="margin-top: 1rem; width: 100%">
          <vs-progress indeterminate color="primary"></vs-progress>
        </div>
      </div>

    </div>
  </vx-card>
</template>

<script>
import trimString from "../mixins/trimString";

const firebase = require("firebase")
// Required for side-effects
require("firebase/firestore")
let db = firebase.firestore()

export default {
  name: "Sliders",
  mixins: [
    trimString
  ],
  data() {
    return {
      content: {
        sliders: []
      },
      progress: false,
      initProgress: false
    }
  },
  created() {
    try {
      this.initProgress = true
      db.collection("content").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.content.id = doc.id
          this.content.sliders = doc.data().sliders
        })
        this.initProgress = false
      })
    } catch (e) {
      this.initProgress = false
      this.$vs.notify({
        color: 'warning',
        title: '¡Oh no!',
        text: 'Hubo un error.'
      })
    }
  },
  computed: {
    isFormValid() {
      return !this.errors.any()
    }
  },
  methods: {
    submitData() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          this.progress = true

          // Content
          let contentRef = db.collection('content').doc(this.content.id)

          //Clone to remove the id
          let cloneContent = Object.assign({}, this.content)
          delete cloneContent.id

          contentRef.update({
            ...cloneContent,
            uid: firebase.auth().currentUser.uid,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp()
          }).then(() => {
            this.progress = false
            this.$vs.notify({
              color: 'success',
              title: 'Sliders',
              text: 'Sliders modificados correctamente.'
            })
          }).catch(() => {
            this.progress = false
            this.$vs.notify({
              color: 'warning',
              title: '¡Oh no!',
              text: 'Hubo un error.'
            })
          })
        }
      })
    },
    async updateSlider(input, slider) {
      this.progress = true
      if (input.target.files && input.target.files[0]) {
        slider.image = await this.submitImages(input.target.files[0])
      }
      this.progress = false
    },
    deleteImage(index) {
      this.content.sliders.splice(index, 1)
    },
    submitImages(image) {
      return new Promise((resolve, reject) => {
        let imageName = Math.random().toString(36).substr(1, 20)
        let fileExtension = '.' + image.name.split('.').pop()
        let imageNameFinal = imageName + fileExtension

        let storageRef = firebase.storage().ref(`content/sliders/${imageNameFinal}`)
        storageRef.put(image)
            .then((snapshot) => {
              snapshot.ref.getDownloadURL().then((url) => {
                resolve(url)
              })
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    changeSlider(id) {
      const slider = document.getElementById(id)
      slider.click()
    },
    addSlider() {
      let obj = {
        path: '/',
        image: 'https://via.placeholder.com/1366x350/FFFF00/000000'
      }
      this.content.sliders.push(obj)
    }
  }
}
</script>
